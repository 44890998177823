import axios from 'axios';
import Vuex from '@/store/index'

const instance = axios.create();
instance.interceptors.response.use(function (response) {
    return response
}, function (error) {
    if (error) {
        // console.log(error);
        // localStorage.removeItem('token');
        // window.location.href = '/'
    }
    return Promise.reject(error)
});

export default instance;
